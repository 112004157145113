import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import {
  ActionDropdown,
  Button,
  CodeBlock,
  Div,
  Footer,
  H3,
  Icon,
  Modal,
  P,
  ToolTip,
} from '@driftt/tide-core';

import { cloneVideo, deleteVideo } from 'api';
import ConfirmDeleteModal from 'components/confirmDeleteModal';
import FullPageSpinner from 'components/fullPageSpinner';
import { UserContext } from 'context/UserContext';
import { downloadVideo, generateShareUrl } from 'utils';
import copyToClipboard, { copyContentToClipboard } from 'utils/copyToClipboard';
import { segmentTrack } from 'utils/metrics';

import './PageHeaderButtons.css';

const EmbeddedPlayerModal = ({ toggle, isOpen, embeddedPlayerUrl, videoId }) => {
  const { userHasFeature } = useContext(UserContext);
  const hasEmbeddedVideoPlayer = userHasFeature('HAS_EMBEDDED_VIDEO_PLAYER');
  const [isCopied, setIsCopied] = useState(false);
  const embedCode = `<iframe width="600" height="400" allow="fullscreen" style="border: none;" src="${embeddedPlayerUrl}" />`;

  function onCopyClick() {
    copyContentToClipboard({ text: embedCode });
    setIsCopied(true);
    segmentTrack('Embed Code Copied', { videoId });

    setTimeout(() => {
      setIsCopied(false);
      toggle();
    }, 1000);
  }

  return (
    <Modal onClose={toggle} open={isOpen} size="small">
      <div>
        <H3>Embed</H3>
        {hasEmbeddedVideoPlayer ? (
          <>
            <P>
              Paste this code in your website&rsquo;s HTML where you want your Drift Video to
              appear.
            </P>
            <CodeBlock language="html">{embedCode}</CodeBlock>
            <Footer>
              <Button onClick={onCopyClick}>{isCopied ? 'Copied!' : 'Copy code'}</Button>
            </Footer>
          </>
        ) : (
          <>
            <P>
              Embed your Drift Videos in a landing page, helpdoc, or your product&rsquo;s HTML to
              create a more immersive experience for your viewers.&nbsp;
            </P>
            <Footer>
              <Button type="tertiary" onClick={toggle}>
                Got it!
              </Button>
            </Footer>
          </>
        )}
      </div>
    </Modal>
  );
};

EmbeddedPlayerModal.propTypes = {
  embeddedPlayerUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  videoId: PropTypes.string,
};

function PageHeaderButtons({
  isDownloadReady,
  videoId,
  publicUrl,
  videoTitle,
  thumbnailUrl,
  isVideoPrivate,
  showMoreOptions = true,
  showCloneButton = false,
  showDownloadButton = false,
}) {
  const { createUrlPath } = useContext(UserContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const embeddedPlayerUrl = publicUrl && publicUrl.replace('/v/', '/embed/');

  const moreOptionsActionsList = [
    {
      label: 'Delete video',
      disabled: false,
      action: (hideDropdown) => {
        hideDropdown();
        setShowDeleteModal(true);
      },
    },
  ];

  const embedActionItem = {
    label: 'Embed on a page',
    disabled: !embeddedPlayerUrl,
    action: (hideDropdown) => {
      hideDropdown();
      setShowEmbedModal(true);
      segmentTrack('Embed Option Clicked', { videoId });
    },
  };

  const shareActionsList = [
    {
      label: isCopied ? 'Copied!' : 'Copy link',
      disabled: false,
      action: (hideDropdown) => {
        copyToClipboard({ publicUrl, thumbnailUrl, videoTitle });
        setIsCopied(true);
        setTimeout(() => {
          hideDropdown();
          setIsCopied(false);
        }, 1000);
      },
    },
  ];

  const handleConfirmDelete = async () => {
    setShowDeleteModal(false);
    setShowSpinner(true);

    try {
      await deleteVideo({ videoId });
      window.location.href = createUrlPath('/video');
    } catch (e) {
      Error(e);
      setShowSpinner(false);
    }
  };

  if (!isVideoPrivate) {
    shareActionsList.push(embedActionItem);
  }

  const handleCloneVideo = () => {
    cloneVideo(videoId)
      .then((response) => {
        if (response) {
          window.open(generateShareUrl(response.data.shareUrl) + '?cloned');
        }
      })
      .catch((err) => {
        Error(err);
      });
  };

  return (
    <Div className="page-header-buttons-container">
      {showCloneButton && (
        <ToolTip flow="down" render={() => 'Clone with my settings'}>
          <Button className="icon-button" type="tertiary" onClick={handleCloneVideo}>
            <Icon name="clone-1"></Icon>
          </Button>
        </ToolTip>
      )}
      {showDownloadButton && (
        <ToolTip flow="down" render={() => 'Download'}>
          <Button
            className="icon-button"
            type="tertiary"
            disabled={!isDownloadReady}
            onClick={() => downloadVideo(videoId)}
          >
            <Icon name="download-2" />
          </Button>
        </ToolTip>
      )}
      <ActionDropdown
        actions={shareActionsList.filter(Boolean)}
        trigger={
          <ToolTip flow="down" render={() => 'Share'}>
            <Button className="icon-button" type="tertiary">
              <Icon name="share-1" />
            </Button>
          </ToolTip>
        }
      />
      {showMoreOptions && (
        <ActionDropdown
          className="more-options-button"
          trigger={
            <ToolTip flow="down" render={() => <div>More</div>}>
              <Button type="seconary" icon="meatballs" />
            </ToolTip>
          }
          actions={moreOptionsActionsList.filter(Boolean)}
        />
      )}
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClickConfirm={handleConfirmDelete}
        onClickCancel={() => setShowDeleteModal(false)}
        target="video"
      />
      <EmbeddedPlayerModal
        isOpen={showEmbedModal}
        toggle={() => setShowEmbedModal(!showEmbedModal)}
        embeddedPlayerUrl={embeddedPlayerUrl}
        videoId={videoId}
      />
      <FullPageSpinner show={showSpinner} />
    </Div>
  );
}

PageHeaderButtons.propTypes = {
  isDownloadReady: PropTypes.bool.isRequired,
  onCreateGifClick: PropTypes.func,
  videoId: PropTypes.number.isRequired,
  publicUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  isVideoPrivate: PropTypes.bool.isRequired,
  showMoreOptions: PropTypes.bool,
  showCloneButton: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
};

export default PageHeaderButtons;
