import React, { useCallback, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import { DefaultContainer, NavigationHeader } from '@driftt/tide-core';

import ClonedRecordingBanner from 'components/clonedRecordingBanner';
import SharePageBreadcrumbs from 'components/pageBreadcrumbs';
import VideoTitle from 'components/videoTitle';
import { UserContext } from 'context/UserContext';
import useToastNotifications from 'hooks/useToastNotifications';
import useUserCustomization from 'hooks/useUserCustomization';
import useVideoData from 'hooks/useVideoData';
import { redirectToPlayerPage } from 'utils';
import { EDIT_CONTROL_TYPE } from 'utils/constants';
import { shouldShowCaptions } from 'utils/playerUtils';

import ViewerStats from '../shareVideo/ViewerStats';

import CustomerPreviewButton from './CustomerPreviewButton';
import PageHeaderButtons from './PageHeaderButtons';
import VideoEditor from './VideoEditor';
import VideoSharing from './VideoSharing';

import './ShareVideoPage.css';

const queryParameters = queryString.parse(window.location.search);
const initiallyCreatingGif = 'create_gif' in queryParameters;
const showClonedRecordingBanner = 'cloned' in queryParameters;

function ShareVideoPage({ videoId }) {
  const {
    videoData,
    isAuthorized,
    playerOptions,
    playerOptionsLoading,
    videoDataLoading,
    handlePlayerCanPlay,
    handlePlayerError,
    handleAudioIncludedChange,
    handlePlayerOptionChange,
    handleVideoDefaultThumbnailChange,
    handleVideoVisibilityChange,
    handleVideoTrimmed,
    handleSaveCreatedGif,
    handleSaveUploadedThumbnail,
    handleDeleteUploadedThumbnail,
  } = useVideoData({ videoId });

  if (!isAuthorized) {
    redirectToPlayerPage();
  }

  const [thumbnails, setThumbnails] = useState([]);
  const [isTrimmingVideo, setIsTrimmingVideo] = useState(false);
  const [unfurlThumbnail, setUnfurlThumbnail] = useState(null);
  const [currentEditControl, setCurrentEditControl] = useState(null);
  const [isCreatingGif, setIsCreatingGif] = useState(initiallyCreatingGif);
  const [unsavedUnfurlThumbnail, setUnsavedUnfurlThumbnail] = useState(null);
  const [tempCustomThumbnailUrl, setTempCustomThumbnailUrl] = useState('');

  const { userCustomization, userCustomizationLoading } = useUserCustomization();

  const { toast, ToastContainer } = useToastNotifications();
  const { isFreeVideoUser } = useContext(UserContext);

  const hasCaptions =
    !userCustomizationLoading && shouldShowCaptions(userCustomization, playerOptions);

  const handleUnfurlThumbnailChange = useCallback(
    (thumbnailUrl) => {
      handleVideoDefaultThumbnailChange(thumbnailUrl);
    },
    [handleVideoDefaultThumbnailChange],
  );

  useEffect(() => {
    const newThumbnails = videoData?.thumbnails;
    const selectedUnfurlThumbnail = newThumbnails?.find((thumbnail) => {
      return thumbnail.default;
    });
    if (
      newThumbnails?.length !== thumbnails?.length ||
      selectedUnfurlThumbnail?.type !== unfurlThumbnail?.type
    ) {
      const hasCustomThumbnail = newThumbnails?.find((thumbnail) => {
        return thumbnail.type === 'custom_thumbnail';
      });
      if (hasCustomThumbnail && tempCustomThumbnailUrl) {
        setTempCustomThumbnailUrl('');
      }
      setThumbnails(newThumbnails);
      setUnfurlThumbnail(selectedUnfurlThumbnail);
      setUnsavedUnfurlThumbnail(selectedUnfurlThumbnail);
    }
  }, [unfurlThumbnail, thumbnails, videoData, tempCustomThumbnailUrl, unsavedUnfurlThumbnail]);

  useEffect(() => {
    if (queryParameters.no_chat) {
      switch (queryParameters.no_chat) {
        case 'gdpr':
          toast.error(
            `Oops! The viewer has to accept your company’s GDPR terms before you can chat with them.`,
          );
          break;
        case 'disabled':
          toast.error(
            `Oops! You can’t start a chat because chat is disabled for this video. Click ‘General settings’ to change this.`,
          );
          break;
        default:
          toast.error(
            'Oops! It’s not you, it’s us. We’re so sorry, an error on our end prevented this chat from being started.',
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryParameters.ra) {
      setCurrentEditControl(EDIT_CONTROL_TYPE.PRIVACY);
    }
  }, []);

  useEffect(() => {
    if (videoData) {
      document.title = `Drift | ${videoData.title}`;
    }
  }, [videoData]);

  return (
    <div className="share-video-page">
      <ToastContainer className="video-share__toast-container" />
      {showClonedRecordingBanner && <ClonedRecordingBanner initialTitle={videoData?.title} />}
      <NavigationHeader>
        <SharePageBreadcrumbs />
        <div className="share-video-page__title-and-options">
          {videoData && <VideoTitle initialValue={videoData.title} videoId={videoId} />}
          <div className="share-video-page__options">
            <CustomerPreviewButton
              publicUrl={videoData && videoData.publicUrl + '?source=library'}
            />
            {!videoDataLoading && (
              <PageHeaderButtons
                isDownloadReady={Boolean(videoData && videoData.videoSources.length > 0)}
                videoId={videoId}
                publicUrl={videoData?.publicUrl}
                thumbnailUrl={videoData?.thumbnailUrl}
                videoTitle={videoData?.title}
                isVideoPrivate={videoData?.isPrivate}
                showDownloadButton={!isFreeVideoUser()}
              />
            )}
          </div>
        </div>
      </NavigationHeader>
      <DefaultContainer className="share-video-page__container">
        <div className="share-video-page__content">
          <div className="video-and-video-settings">
            <VideoEditor
              loading={videoDataLoading || (videoData && videoData.videoSources.length === 0)}
              onPlayerCanPlay={handlePlayerCanPlay}
              onPlayerError={handlePlayerError}
              onVideoTrimmed={handleVideoTrimmed}
              videoData={videoData}
              isTrimmingVideo={isTrimmingVideo}
              setIsTrimmingVideo={setIsTrimmingVideo}
              isCreatingGif={isCreatingGif}
              setIsCreatingGif={setIsCreatingGif}
              onSaveCreatedGif={handleSaveCreatedGif}
              onTrimVideoClose={() => {
                setCurrentEditControl(null);
              }}
              hasCaptions={hasCaptions}
            />
            {!videoDataLoading && (
              <ViewerStats
                loading={playerOptionsLoading}
                videoData={videoData}
                videoId={videoId}
              ></ViewerStats>
            )}
          </div>
          {!videoDataLoading && (
            <div className="video-sharing-options">
              <VideoSharing
                loading={videoDataLoading || playerOptionsLoading}
                videoData={videoData}
                playerOptions={playerOptions}
                videoId={videoId}
                unfurlThumbnail={unfurlThumbnail}
                thumbnails={thumbnails}
                isAudioIncluded={videoData.isAudioIncluded}
                currentEditControl={currentEditControl}
                unsavedUnfurlThumbnail={unsavedUnfurlThumbnail}
                tempCustomThumbnailUrl={tempCustomThumbnailUrl}
                setTempCustomThumbnailUrl={setTempCustomThumbnailUrl}
                onThumbnailUpload={handleSaveUploadedThumbnail}
                onThumbnailDelete={handleDeleteUploadedThumbnail}
                setUnsavedUnfurlThumbnail={setUnsavedUnfurlThumbnail}
                onAudioIncludedChange={handleAudioIncludedChange}
                onUnfurlThumbnailChange={handleUnfurlThumbnailChange}
                onPlayerOptionChange={handlePlayerOptionChange}
                onTrimClick={() => {
                  setIsTrimmingVideo(true);
                  setIsCreatingGif(false);
                }}
                onEditControlClick={(control) => {
                  setCurrentEditControl(control);
                }}
                videoVisibility={videoData.videoVisibility}
                onVideoVisibilityChange={handleVideoVisibilityChange}
              />
            </div>
          )}
        </div>
      </DefaultContainer>
    </div>
  );
}

ShareVideoPage.propTypes = {
  videoId: PropTypes.number.isRequired,
};

export default ShareVideoPage;
